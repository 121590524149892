import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import QRCode from "qrcode.react"
import { graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div style={{ textAlign: "center" }}>
      <h1>Your personal Wedding Planner</h1>
      <p>
        Plan your joy day with MarryNow. <br />
        It's
        <strong> Easy, Fun and Free!</strong>
      </p>
      <div style={{ maxWidth: 600, margin: '50px auto' }}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))", alignItems: "center" }}>
          <div style={{ borderRight: "1px solid #DDD" }}>
            <OutboundLink href="https://apps.apple.com/us/app/marrynow/id1503192124">
              <Img
                fixed={data.appStoreBadge.childImageSharp.fixed}
                alt="appstore badge"
              />
            </OutboundLink>
            <p>
              <strong>Scan Me</strong>
            </p>
            <QRCode
              value="https://apps.apple.com/us/app/marrynow/id1503192124"
              fgColor="#EA614F"
              size="88"
            />
          </div>
          <div>
            <OutboundLink href="https://play.google.com/store/apps/details?id=vn.marrynow.planner">
              <Img
                fixed={data.playStoreBadge.childImageSharp.fixed}
                alt="google play badge"
              />
            </OutboundLink>
            <p>
              <strong>Scan Me</strong>
            </p>
            <QRCode
              value="https://play.google.com/store/apps/details?id=vn.marrynow.planner"
              fgColor="#EA614F"
              size="88"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
export const query = graphql`
  query {
    appStoreBadge: file(relativePath: { eq: "appstore-badge.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    playStoreBadge: file(relativePath: { eq: "google-play-badge.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
